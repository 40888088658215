<template>
	<div class="md-layout">
		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<md-icon>info</md-icon>
					</div>
					<h4 class="title">{{ $t("general.informations") }}</h4>
					<p v-html="$t('troubleshooting.description')" />
					<br />
				</md-card-header>
			</md-card>
		</div>
		<div class="md-layout-item md-size-100">
			<md-card>
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<div class="card-icon">
						<drop-down direction="down">
							<md-button
								slot="title"
								class="md-button md-warning md-round dropdown-toggle"
								data-toggle="dropdown">
								<md-icon>settings_input_component</md-icon>
							</md-button>
							<ul class="dropdown-menu" :class="{ 'dropdown-menu-right': false }">
								<li>
									<a href="#" class="trouble-menu" @click="setTroubleshootType('Ping')">Ping</a>
								</li>
								<li>
									<a href="#" class="trouble-menu" @click="setTroubleshootType('Traceroute')"
										>Traceroute</a
									>
								</li>
								<li>
									<a href="#" class="trouble-menu" @click="setTroubleshootType('Neighborlist scan')"
										>Neighborlist scan</a
									>
								</li>
								<li>
									<a href="#" class="trouble-menu" @click="setTroubleshootType('Bandwidth test')"
										>Bandwidth test</a
									>
								</li>
								<li>
									<a href="#" class="trouble-menu" @click="setTroubleshootType('Torch')">Torch</a>
								</li>
								<li>
									<a href="#" class="trouble-menu" @click="setTroubleshootType('DHCP leases')"
										>DHCP leases</a
									>
								</li>
								<li>
									<a href="#" class="trouble-menu" @click="setTroubleshootType('IP Scan')">IP Scan</a>
								</li>
							</ul>
						</drop-down>
					</div>
					<h4 class="title">{{ troubleshootType }}</h4>
				</md-card-header>
				<md-card-content v-if="troubleshootType == ''"></md-card-content>
				<md-card-content class="no-padding" v-if="troubleshootType == 'Ping'">
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<p v-html="$t('troubleshooting.ping')" />
						<br /><br />
					</md-card-header>
					<ValidationObserver v-slot="{ handleSubmit }">
						<form @submit.prevent="handleSubmit(handlePing)">
							<div class="md-layout">
								<div class="md-layout-item md-size-20"></div>
								<div class="md-layout-item md-size-20">
									<ValidationProvider
										name="phost"
										rules="required|ip_or_fqdn"
										v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Host</label>
											<md-input v-model="ping.host" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-20">
									<ValidationProvider
										name="psrcaddress"
										rules="ip_or_fqdn"
										v-slot="{ passed, failed }">
										<md-field
											:class="[{ 'md-error': failed }, { 'md-valid': passed }]"
											class="md-no-border">
											<md-autocomplete
												v-model="ping.src_address"
												:md-options="
													ips.map((ip) => ({
														value: ip.address,
														label: `${ip.address} (${ip.interface})`,
														toLowerCase: () => ip.address.toLowerCase(),
														toString: () => ip.address,
													}))
												">
												<label>Src Address</label>
												<template slot="md-autocomplete-item" slot-scope="{ item }">
													{{ item.label }}
												</template>
											</md-autocomplete>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-100"></div>
								<div class="md-layout-item md-size-20"></div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider
										name="count"
										rules="required|integer"
										v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Count</label>
											<md-input v-model="ping.count" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider name="interface">
										<md-field>
											<label>Interface</label>
											<md-select v-model="ping.interface" name="interfaces" id="interfaces">
												<md-option value=""></md-option>
												<md-option
													v-for="item in interfaces"
													:value="item.name"
													:key="item.name"
													>{{ item.name }}</md-option
												>
											</md-select>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider
										name="interval"
										rules="required|integer"
										v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Interval (seconds)</label>
											<md-input v-model="ping.interval" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider
										name="size"
										rules="required|integer"
										v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Size (bytes)</label>
											<md-input v-model="ping.size" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-10"></div>
								<div class="md-layout-item md-size-10">
									<md-button
										type="submit"
										:class="[{ 'md-info': !pingRunning }, { 'md-danger': pingRunning }]">
										{{ pingRunning ? "Stop" : "Start" }}
									</md-button>
								</div>
							</div>
						</form>
					</ValidationObserver>
				</md-card-content>
				<md-card-content class="no-padding" v-if="troubleshootType == 'Traceroute'">
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<p v-html="$t('troubleshooting.traceroute')" />
						<br /><br />
					</md-card-header>
					<ValidationObserver v-slot="{ handleSubmit }">
						<form @submit.prevent="handleSubmit(handleTraceroute)">
							<div class="md-layout">
								<div class="md-layout-item md-size-20"></div>
								<div class="md-layout-item md-size-20">
									<ValidationProvider
										name="thost"
										rules="required|ip_or_fqdn"
										v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Host</label>
											<md-input v-model="traceroute.host" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-20">
									<ValidationProvider
										name="tsrcaddress"
										rules="ip_or_fqdn"
										v-slot="{ passed, failed }">
										<md-field
											:class="[{ 'md-error': failed }, { 'md-valid': passed }]"
											class="md-no-border">
											<md-autocomplete
												v-model="traceroute.src_address"
												:md-options="
													ips.map((ip) => ({
														value: ip.address,
														label: `${ip.address} (${ip.interface})`,
														toLowerCase: () => ip.address.toLowerCase(),
														toString: () => ip.address,
													}))
												">
												<label>Src Address</label>
												<template slot="md-autocomplete-item" slot-scope="{ item }">
													{{ item.label }}
												</template>
											</md-autocomplete>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-100"></div>
								<div class="md-layout-item md-size-20"></div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider
										name="tcount"
										rules="required|integer"
										v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Count</label>
											<md-input v-model="traceroute.count" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider name="tinterface">
										<md-field>
											<label>Interface</label>
											<md-select v-model="traceroute.interface" name="interfaces" id="interfaces">
												<md-option value=""></md-option>
												<md-option
													v-for="item in interfaces"
													:value="item.name"
													:key="item.name"
													>{{ item.name }}</md-option
												>
											</md-select>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider
										name="max_hops"
										rules="required|integer"
										v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Max hops</label>
											<md-input v-model="traceroute.max_hops" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider
										name="duration"
										rules="required|integer"
										v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Duration</label>
											<md-input v-model="traceroute.duration" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-10"></div>
								<div class="md-layout-item md-size-10">
									<md-button
										type="submit"
										:class="[
											{ 'md-info': !tracerouteRunning },
											{ 'md-danger': tracerouteRunning },
										]">
										{{ tracerouteRunning ? "Stop" : "Start" }}
									</md-button>
								</div>
							</div>
						</form>
					</ValidationObserver>
				</md-card-content>
				<md-card-content class="no-padding" v-if="troubleshootType == 'Neighborlist scan'">
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<p v-html="$t('troubleshooting.neighbor')" />
						<br /><br />
					</md-card-header>
					<div class="md-layout">
						<div class="md-layout-item md-size-90"></div>
						<div class="md-layout-item md-size-10">
							<md-button
								@click.native="handleNeighburlist()"
								:class="[{ 'md-info': !neighborlistRunning }, { 'md-danger': neighborlistRunning }]">
								{{ neighborlistRunning ? "Stop" : "Start" }}
							</md-button>
						</div>
					</div>
				</md-card-content>
				<md-card-content class="no-padding" v-if="troubleshootType == 'DHCP leases'">
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<p v-html="$t('troubleshooting.dhcp_leasing')" />
						<br /><br />
					</md-card-header>
					<div class="md-layout">
						<div class="md-layout-item md-size-90"></div>
						<div class="md-layout-item md-size-10">
							<md-button
								@click.native="handleDhcpLease()"
								:class="[{ 'md-info': !dhcpLeasesRunning }, { 'md-danger': dhcpLeasesRunning }]">
								{{ dhcpLeasesRunning ? "Stop" : "Start" }}
							</md-button>
						</div>
					</div>
				</md-card-content>
				<md-card-content class="no-padding" v-if="troubleshootType == 'Bandwidth test'">
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<p v-html="$t('troubleshooting.bandwidth_test')" />
						<br /><br />
					</md-card-header>
					<ValidationObserver v-slot="{ handleSubmit }">
						<form @submit.prevent="handleSubmit(handleBandwidthTest)">
							<div class="md-layout">
								<div class="md-layout-item md-size-20">
									<ValidationProvider name="server" rules="required" v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Server</label>
											<md-select v-model="bandwidthTest.server" name="server" id="server">
												<md-option
													v-for="server in bandwidthTestServers"
													:value="server.id"
													:key="server.id"
													>{{ server.name }}</md-option
												>
											</md-select>
										</md-field>
									</ValidationProvider>
								</div>

								<div class="md-layout-item md-size-20">
									<ValidationProvider
										name="duration"
										rules="required|integer"
										v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Duration</label>
											<md-input v-model="bandwidthTest.duration" type="text"></md-input>
										</md-field>
									</ValidationProvider>
								</div>

								<div class="md-layout-item md-size-20">
									<ValidationProvider name="direction" rules="required" v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Direction</label>
											<md-select
												v-model="bandwidthTest.direction"
												name="direction"
												id="direction">
												<md-option value="receive">Receive</md-option>
												<md-option value="send">Send</md-option>
												<md-option value="both">Both</md-option>
											</md-select>
										</md-field>
									</ValidationProvider>
								</div>

								<div class="md-layout-item md-size-20">
									<ValidationProvider name="protocol" v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Protocol</label>
											<md-select v-model="bandwidthTest.protocol" name="protocol" id="protocol">
												<md-option value="">TCP (default)</md-option>
												<md-option value="tcp">TCP</md-option>
												<md-option value="udp">UDP</md-option>
											</md-select>
										</md-field>
									</ValidationProvider>
								</div>

								<div class="md-layout-item md-size-20">
									<md-button
										type="submit"
										:class="[
											{ 'md-info': !bandwidthTestRunning },
											{ 'md-danger': bandwidthTestRunning },
										]"
										>{{ bandwidthTestRunning ? "Stop" : "Start" }}</md-button
									>
								</div>
							</div>
						</form>
					</ValidationObserver>
				</md-card-content>
				<md-card-content class="no-padding" v-if="troubleshootType == 'Torch'">
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<p v-html="$t('troubleshooting.torch')" />
						<br /><br />
					</md-card-header>
					<ValidationObserver v-slot="{ handleSubmit }">
						<form @submit.prevent="handleSubmit(handleTorch)">
							<div class="md-layout">
								<div class="md-layout-item md-size-20"></div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider v-slot="{ passed, failed }" name="thinterface">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Interface</label>
											<md-select v-model="torch.interface" name="thinterface" id="thinterface">
												<md-option
													v-for="item in interfaces"
													:value="item.name"
													:key="item.name"
													>{{ item.name }}</md-option
												>
											</md-select>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-20">
									<ValidationProvider name="tsrchost" rules="ip_or_fqdn" v-slot="{ passed, failed }">
										<md-field
											:class="[{ 'md-error': failed }, { 'md-valid': passed }]"
											class="md-no-border">
											<md-autocomplete
												v-model="torch.src_host"
												:md-options="
													ips.map((ip) => ({
														value: ip.address,
														label: `${ip.address} (${ip.interface})`,
														toLowerCase: () => ip.address.toLowerCase(),
														toString: () => ip.address,
													}))
												">
												<label>Src Address</label>
												<template slot="md-autocomplete-item" slot-scope="{ item }">
													{{ item.label }}
												</template>
											</md-autocomplete>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-20">
									<ValidationProvider name="tdsthost" rules="ip_or_fqdn" v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Dst Address</label>
											<md-input v-model="torch.dst_host" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider name="thport" rules="integer" v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Port</label>
											<md-input v-model="torch.port" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-20"></div>
								<div class="md-layout-item md-size-20"></div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider name="thinterface" v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Protocol</label>
											<md-select v-model="torch.protocol" name="thprotocol" id="thprotocol">
												<md-option
													v-for="(protocol, key) in protocols"
													:value="protocol"
													:key="key"
													>{{ protocol }}</md-option
												>
											</md-select>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider name="thvlan" rules="integer" v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Vlan-Id</label>
											<md-input v-model="torch.vlan_id" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider
										name="thduration"
										rules="required|integer"
										v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Duration</label>
											<md-input v-model="torch.duration" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-40"></div>
								<div class="md-layout-item md-size-10">
									<md-button
										type="submit"
										:class="[{ 'md-info': !torchRunning }, { 'md-danger': torchRunning }]">
										{{ torchRunning ? "Stop" : "Start" }}
									</md-button>
								</div>
							</div>
						</form>
					</ValidationObserver>
				</md-card-content>
				<md-card-content class="no-padding" v-if="troubleshootType == 'IP Scan'">
					<md-card-header class="md-card-header-icon md-card-header-blue">
						<p v-html="$t('troubleshooting.ip_scan')" />
						<br /><br />
					</md-card-header>
					<ValidationObserver v-slot="{ handleSubmit }">
						<form @submit.prevent="handleSubmit(handleIPScan)">
							<div class="md-layout">
								<div class="md-layout-item md-size-20"></div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider v-slot="{ passed, failed }" name="ipsinterface">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Interface</label>
											<md-select v-model="ipscan.interface" name="ipsinterface" id="ipsinterface">
												<md-option
													v-for="item in interfaces"
													:value="item.name"
													:key="item.name"
													>{{ item.name }}</md-option
												>
											</md-select>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-20">
									<ValidationProvider name="ipscidr" rules="isCidr" v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Cidr</label>
											<md-input v-model="ipscan.cidr" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-10">
									<ValidationProvider
										name="ipsduration"
										rules="required|integer"
										v-slot="{ passed, failed }">
										<md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
											<label>Duration</label>
											<md-input v-model="ipscan.duration" type="text"> </md-input>

											<slide-y-down-transition>
												<md-icon class="error" v-show="failed">close</md-icon>
											</slide-y-down-transition>
											<slide-y-down-transition>
												<md-icon class="success" v-show="passed">done</md-icon>
											</slide-y-down-transition>
										</md-field>
									</ValidationProvider>
								</div>
								<div class="md-layout-item md-size-30"></div>
								<div class="md-layout-item md-size-10">
									<md-button
										type="submit"
										:class="[{ 'md-info': !ipScanRunning }, { 'md-danger': ipScanRunning }]">
										{{ ipScanRunning ? "Stop" : "Start" }}
									</md-button>
								</div>
							</div>
						</form>
					</ValidationObserver>
				</md-card-content>
			</md-card>
			<md-card class="result-card">
				<md-card-header class="md-card-header-icon md-card-header-blue">
					<h4 class="title">Results</h4>
				</md-card-header>
				<md-card-content
					v-if="
						troubleshootType == 'Ping' ||
						troubleshootType == 'Traceroute' ||
						troubleshootType == 'Bandwidth test' ||
						troubleshootType == 'Torch' ||
						troubleshootType == 'IP Scan'
					">
					<pre class="modal-code" ref="resultsTarget">{{ resultText }}</pre>
				</md-card-content>
				<md-card-content v-if="troubleshootType == 'Neighborlist scan'">
					<md-table v-model="neighborlistData" table-header-color="green">
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell :md-label="$t('fields.interface.label')">{{ item.interface }}</md-table-cell>
							<md-table-cell md-label="Address">{{ item.address }}</md-table-cell>
							<md-table-cell md-label="Mac address">{{ item.mac_address }}</md-table-cell>
							<md-table-cell md-label="Version">{{ item.version }}</md-table-cell>
							<md-table-cell md-label="Identity">{{ item.identity }}</md-table-cell>
							<md-table-cell md-label="Uptime">{{ item.uptime }}</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-content v-if="troubleshootType == 'DHCP leases'">
					<md-table v-model="dhcpLeaselistData" table-header-color="green">
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell md-label="Address">{{ item.comment }}<br />{{ item.address }}</md-table-cell>
							<md-table-cell md-label="Mac address">{{ item.mac_address }}</md-table-cell>
							<md-table-cell md-label="Host Name">{{ item.host_name }}</md-table-cell>
							<md-table-cell md-label="Server">{{ item.server }}</md-table-cell>
							<md-table-cell md-label="Status">{{ item.status }}</md-table-cell>
							<md-table-cell md-label="Last seen">{{ item.last_seen }}</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
			</md-card>
		</div>
	</div>
</template>
<script>
import evoapi from "@/services/evoapi";
import TokenService from "@/services/token";
import { SlideYDownTransition } from "vue2-transitions";
import Swal from "sweetalert2";
import { extend } from "vee-validate";
import { required, integer } from "vee-validate/dist/rules";
import { isIP } from "is-ip";
const isCidr = require("is-cidr");
const isFQDN = require("is-fqdn");

extend("required", required);
extend("integer", integer);
extend("ip_or_fqdn", (value) => {
	return isIP(value) || isFQDN(value);
});
extend("selectRequired", (value) => {
	return value.length > 0;
});
extend("isCidr", (value) => {
	return isCidr.v4(value);
});

const DEFAULT_DURATION = 30;
const DEFAULT_COUNT = 10;

export default {
	components: {
		SlideYDownTransition,
	},
	data() {
		return {
			troubleshootType: "",
			device_id: null,
			name: "",
			name2: "",
			stream: null,
			ping: {
				host: "",
				count: DEFAULT_COUNT,
				interface: "",
				interval: 1,
				size: 64,
				src_address: "",
				lastResponse: {},
			},
			traceroute: {
				host: "",
				count: 30,
				interface: "",
				max_hops: 30,
				duration: DEFAULT_DURATION,
				src_address: "",
				traceList: [],
				traceIndexes: [],
			},
			bandwidthTest: {
				duration: DEFAULT_DURATION,
				direction: "receive",
				protocol: "",
				server: null,
			},
			torch: {
				interface: "",
				src_host: "",
				dst_host: "",
				port: "",
				protocol: "",
				vlan_id: "",
				duration: DEFAULT_DURATION,
			},
			ipscan: {
				interface: "",
				duration: DEFAULT_DURATION,
				cidr: "",
			},
			interfaces: [],
			ips: [],
			pingRunning: false,
			tracerouteRunning: false,
			neighborlistRunning: false,
			bandwidthTestRunning: false,
			torchRunning: false,
			dhcpLeasesRunning: false,
			ipScanRunning: false,
			commandRunning: false,
			resultText: "",
			neighborlistData: [],
			dhcpLeaselistData: [],
			directions: ["receive", "transmit", "both"],
			protocols: [
				"",
				"dccp",
				"ddp",
				"egp",
				"encap",
				"etherip",
				"ggp",
				"gre",
				"hmp",
				"icmp",
				"icmpv6",
				"idpr-cmtp",
				"igmp",
				"ipencap",
				"ipip",
				"ipsec-ah",
				"ipsec-esp",
				"ipv6-encap",
				"ipv6-frag",
				"ipv6-nonxt",
				"ipv6-opts",
				"ipv6-route",
				"iso-tp4",
				"l2tp",
				"ospf",
				"pim",
				"pup",
				"rdp",
				"rspf",
				"rsvp",
				"sctp",
				"st",
				"tcp",
				"udp",
				"udp-lite",
				"vmtp",
				"vrrp",
				"xns-idp",
				"xtp",
			],
			bandwidthTestServers: [],
			selectedServer: null,
		};
	},
	created() {
		this.device_id = this.$route.params.device_id;
	},
	mounted() {
		let userData = TokenService.getUser();
		evoapi
			.get("/customers/" + userData.customerId + "/troubleshoot/" + this.device_id + "/interfaces")
			.then((response) => {
				this.interfaces = response.data;
				this.torch.interface = this.interfaces[0].name;
			});
		evoapi
			.get("/customers/" + userData.customerId + "/troubleshoot/" + this.device_id + "/ips")
			.then((response) => {
				this.ips = response.data.map((ip) => ({
					...ip,
					address: ip.address.split("/")[0],
				}));
			});
		evoapi
			.get("/customers/" + userData.customerId + "/troubleshoot/bandwidth-test-servers")
			.then((response) => {
				this.bandwidthTestServers = response.data;
				if (this.bandwidthTestServers.length > 0) {
					this.bandwidthTest.server = this.bandwidthTestServers[0];
				}
			})
			.catch((error) => {
				//console.error("Failed to fetch bandwidth test servers:", error);
			});
	},
	methods: {
		setTroubleshootType(type) {
			if (this.commandRunning) {
				Swal.fire({
					title: this.$t("dialogs.troubleshooting_update.title"),
					text: this.$t("dialogs.troubleshooting_update.content"),
					buttonsStyling: false,
					confirmButtonClass: "md-button md-success",
					type: "warning",
				});
			} else {
				this.troubleshootType = type;
			}
		},
		validate() {
			return this.$refs.form.validate().then((res) => {
				this.$emit("on-validated", res);
				return res;
			});
		},
		handlePing() {
			if (this.pingRunning) {
				this.stream.close();
				this.pingRunning = false;
				this.commandRunning = false;
				this.resultText = this.resultText.concat(
					"\r\n" +
						`sent=${this.ping.lastResponse.sent} ` +
						`received=${this.ping.lastResponse.received} ` +
						`packet-loss=${this.ping.lastResponse.packet_loss} ` +
						`min-rtt=${this.ping.lastResponse.min_rtt} ` +
						`avg-rtt=${this.ping.lastResponse.avg_rtt} ` +
						`max-rtt=${this.ping.lastResponse.max_rtt} ` +
						`src-address=${this.ping.src_address} `
				);
				this.ping.lastResponse = {};
			} else {
				this.pingRunning = true;
				this.commandRunning = true;
				let userData = TokenService.getUser();
				let jwt = TokenService.getLocalAccessToken();
				let baseUrl = `/api-backoffice/customers/${userData.customerId}/troubleshoot/${this.device_id}/ping?jwt=${jwt}`;
				let params = `&host=${this.ping.host}&count=${this.ping.count}&interval=${this.ping.interval}&size=${this.ping.size}&interface=${this.ping.interface}&src_address=${this.ping.src_address}`;
				this.resultText = "SEQ\tHOST\t\t\t\t\t\tSIZE\tTTL\tTIME\tSTATUS";
				this.stream = new EventSource(`${baseUrl}${params}`);
				this.stream.addEventListener("ping-response", (e) => {
					let p = JSON.parse(e.data);
					this.ping.lastResponse = JSON.parse(e.data);
					this.resultText = this.resultText.concat(
						"\r\n" + `${p.seq}\t${p.host}\t\t\t\t\t\t${p.size}\t${p.ttl}\t${p.time}\t${p.status}`
					);
				});
				this.stream.onerror = (e) => {
					this.handlePing();
				};
			}
		},
		handleTraceroute() {
			if (this.tracerouteRunning) {
				this.stream.close();
				this.tracerouteRunning = false;
				this.commandRunning = false;
				this.traceroute.traceList = [];
			} else {
				this.tracerouteRunning = true;
				this.commandRunning = true;
				let userData = TokenService.getUser();
				let jwt = TokenService.getLocalAccessToken();
				let baseUrl = `/api-backoffice/customers/${userData.customerId}/troubleshoot/${this.device_id}/traceroute?jwt=${jwt}`;
				let params = `&host=${this.traceroute.host}&count=${this.traceroute.count}&max_hops=${this.traceroute.max_hops}&duration=${this.traceroute.duration}&interface=${this.traceroute.interface}&src_address=${this.traceroute.src_address}`;
				this.resultText = "#\tADDRESS\t\t\t\t\t\tLOSS\tSENT\tLAST\tAVG\tBEST\tWORST";
				this.stream = new EventSource(`${baseUrl}${params}`);
				this.stream.addEventListener("traceroute-response", (e) => {
					let t = JSON.parse(e.data);
					let index = this.traceroute.traceIndexes[t.address];
					if (index == undefined) {
						index = this.traceroute.traceList.push({}) - 1;
						this.traceroute.traceIndexes[t.address] = index;
					}
					this.traceroute.traceList[index] = {
						address: t.address.padEnd(10),
						loss: t.loss,
						sent: t.sent,
						last: t.last,
						avg: t.avg,
						best: t.best,
						worst: t.worst,
					};
					this.resultText = "#\tADDRESS\t\t\t\t\t\tLOSS\tSENT\tLAST\tAVG\tBEST\tWORST";
					// Update this.resultText iterating all this.traceList
					this.traceroute.traceList.forEach((item, index) => {
						this.resultText = this.resultText.concat(
							"\r\n" +
								`${index}\t${item.address}\t\t\t\t\t${item.loss}%\t${item.sent}\t${item.last}\t${item.avg}\t${item.best}\t${item.worst}`
						);
					});
				});
				this.stream.onerror = (e) => {
					this.handleTraceroute();
				};
			}
		},
		handleNeighburlist() {
			if (this.neighborlistRunning) {
				this.neighborlistRunning = false;
				this.commandRunning = false;
			} else {
				this.neighborlistRunning = true;
				this.commandRunning = true;
				this.neighborlistData = [];

				let userData = TokenService.getUser();
				evoapi
					.get("/customers/" + userData.customerId + "/troubleshoot/" + this.device_id + "/neighbor-list")
					.then((response) => {
						this.neighborlistData = response.data;
						this.neighborlistRunning = false;
						this.commandRunning = false;
					});
			}
		},
		handleBandwidthTest() {
			if (this.bandwidthTestRunning) {
				this.stream.close();
				this.bandwidthTestRunning = false;
				this.commandRunning = false;
			} else {
				this.bandwidthTestRunning = true;
				this.commandRunning = true;
				let userData = TokenService.getUser();
				let jwt = TokenService.getLocalAccessToken();
				let baseUrl = `/api-backoffice/customers/${userData.customerId}/troubleshoot/${this.device_id}/bandwidth-test?jwt=${jwt}`;

				// Usa il server completo per i parametri
				let serverParams = this.selectedServer
					? {
							id: this.selectedServer.id,
							name: this.selectedServer.name,
							user: this.selectedServer.user,
							ip_address: this.selectedServer.ip_address,
					  }
					: null;

				let params = `&duration=${this.bandwidthTest.duration}&direction=${this.bandwidthTest.direction}&protocol=${this.bandwidthTest.protocol}`;

				if (serverParams) {
					params += `&server=${encodeURIComponent(JSON.stringify(serverParams))}`;
				}

				this.stream = new EventSource(`${baseUrl}${params}`);
				this.stream.addEventListener("bandwidth-test-response", (e) => {
					let bt = JSON.parse(e.data);
					this.resultText =
						`\t\t${"status".padStart(20)}: ${bt.status}\r\n` +
						`\t\t${"duration".padStart(20)}: ${bt.duration}\r\n` +
						`\t\t${"tx-current".padStart(20)}: ${(
							Math.round((bt.tx_current / 1048576) * 100) / 100
						).toFixed(2)} Mbps\r\n` +
						`\t\t${"tx-10-second-average".padStart(20)}: ${(
							Math.round((bt.tx_10_second_average / 1048576) * 100) / 100
						).toFixed(2)} Mbps\r\n` +
						`\t\t${"tx-total-average".padStart(20)}: ${(
							Math.round((bt.tx_total_average / 1048576) * 100) / 100
						).toFixed(2)} Mbps\r\n` +
						`\t\t${"rx-current".padStart(20)}: ${(
							Math.round((bt.rx_current / 1048576) * 100) / 100
						).toFixed(2)} Mbps\r\n` +
						`\t\t${"rx-10-second-average".padStart(20)}: ${(
							Math.round((bt.rx_10_second_average / 1048576) * 100) / 100
						).toFixed(2)} Mbps\r\n` +
						`\t\t${"rx-total-average".padStart(20)}: ${(
							Math.round((bt.rx_total_average / 1048576) * 100) / 100
						).toFixed(2)} Mbps\r\n` +
						`\t\t${"lost-packets".padStart(20)}: ${bt.lost_packets}\r\n` +
						`\t\t${"random-data".padStart(20)}: ${bt.random_data}\r\n` +
						`\t\t${"direction".padStart(20)}: ${bt.direction}\r\n` +
						`\t\t${"tx-size".padStart(20)}: ${bt.tx_size}\r\n` +
						`\t\t${"rx-size".padStart(20)}: ${bt.rx_size}\r\n` +
						`\t\t${"connection-count".padStart(20)}: ${bt.connection_count}\r\n` +
						`\t\t${"local-cpu-load".padStart(20)}: ${bt.local_cpu_load}%\r\n` +
						`\t\t${"remote-cpu-load".padStart(20)}: ${bt.remote_cpu_load}%`;
				});

				this.stream.onerror = (e) => {
					this.handleBandwidthTest();
				};
			}
		},
		handleTorch() {
			if (this.torchRunning) {
				this.stream.close();
				this.torchRunning = false;
				this.commandRunning = false;
			} else {
				let resultTextHeader = `${"IP PROTOCOL".padStart(10)}\t${"SRC-ADDRESS".padStart(
					20
				)}\t${"SRC PORT".padStart(10)}\t`;
				resultTextHeader += `${"DST ADDRESS".padStart(20)}\t${"DST PORT".padStart(10)}\t`;
				resultTextHeader += `${"TX".padStart(10)}\t${"RX".padStart(10)}\t${"TX PACKETS".padStart(
					10
				)}\t${"RX PACKETS".padStart(10)}\t`;
				this.torchRunning = true;
				this.commandRunning = true;
				let userData = TokenService.getUser();
				let jwt = TokenService.getLocalAccessToken();
				let baseUrl = `/api-backoffice/customers/${userData.customerId}/troubleshoot/${this.device_id}/torch?jwt=${jwt}`;
				let params = `&interface=${this.torch.interface}&src_host=${this.torch.src_host}&dst_host=${this.torch.dst_host}&port=${this.torch.port}&protocol=${this.torch.protocol}&vlan_id=${this.torch.vlan_id}&duration=${this.torch.duration}`;
				this.resultText = resultTextHeader;
				this.stream = new EventSource(`${baseUrl}${params}`);
				this.stream.addEventListener("torch-test-response", (e) => {
					this.resultText = resultTextHeader;
					let p = JSON.parse(e.data);
					p.forEach(function (item, i) {
						this.resultText = this.resultText.concat(
							"\r\n" +
								`${item.ip_protocol.padStart(10)}\t${item.src_address.padStart(
									20
								)}\t${item.src_port.padStart(10)}\t`
						);
						this.resultText = this.resultText.concat(
							`${item.dst_address.padStart(20)}\t${item.dst_port.padStart(10)}\t`
						);
						this.resultText = this.resultText.concat(
							`${item.tx.padStart(10)}\t${item.rx.padStart(10)}\t${item.tx_packets.padStart(
								10
							)}\t${item.rx_packets.padStart(10)}\t`
						);
					}, this);
				});
				this.stream.onerror = (e) => {
					this.handleTorch();
				};
			}
		},
		handleDhcpLease() {
			if (this.dhcpLeasesRunning) {
				this.dhcpLeasesRunning = false;
				this.commandRunning = false;
			} else {
				this.dhcpLeasesRunning = true;
				this.commandRunning = true;
				this.dhcpLeaselistData = [];
				let userData = TokenService.getUser();
				evoapi
					.get("/customers/" + userData.customerId + "/troubleshoot/" + this.device_id + "/dhcp-lease")
					.then((response) => {
						this.dhcpLeaselistData = response.data;
						this.dhcpLeasesRunning = false;
						this.commandRunning = false;
					});
			}
		},
		handleIPScan() {
			if (this.ipScanRunning) {
				this.stream.close();
				this.ipScanRunning = false;
				this.commandRunning = false;
			} else {
				let resultTextHeader = `${"ADDRESS".padStart(20)}\t${"MAC ADDRESS".padStart(20)}\t${"TIME".padStart(
					10
				)}\t`;
				this.ipScanRunning = true;
				this.commandRunning = true;
				let userData = TokenService.getUser();
				let jwt = TokenService.getLocalAccessToken();
				let baseUrl = `/api-backoffice/customers/${userData.customerId}/troubleshoot/${this.device_id}/ip-scan?jwt=${jwt}`;
				let params = `&interface=${this.ipscan.interface}&duration=${this.ipscan.duration}&cidr=${this.ipscan.cidr}`;
				this.resultText = resultTextHeader;
				this.stream = new EventSource(`${baseUrl}${params}`);
				this.stream.addEventListener("ip-scan-response", (e) => {
					this.resultText = resultTextHeader;
					let p = JSON.parse(e.data);
					p.forEach(function (item, i) {
						this.resultText = this.resultText.concat(
							"\r\n" +
								`${item.address.padStart(20)}\t${item.mac_address.padStart(20)}\t${item.time.padStart(
									10
								)}\t`
						);
					}, this);
				});
				this.stream.onerror = (e) => {
					this.handleIPScan();
				};
			}
		},
	},
	beforeDestroy() {
		this.stream.close();
	},
	watch: {
		troubleshootType(new_value, old_value) {
			if (new_value != old_value) {
				this.resultText = "";
				this.neighborlistData = [];
			}
		},
		"bandwidthTest.server": {
			handler(newValue) {
				this.selectedServer = this.bandwidthTestServers.find((s) => s.id === newValue);
			},
		},
	},
};
</script>
<style>
.md-card .md-card-header a.trouble-menu {
	color: #333333 !important;
}
.no-padding {
	padding: 0px !important;
	padding-bottom: 24px !important;
}
.modal-code {
	text-align: left;
	font-family: "Courier New", Courier, monospace;
	font-size: 12px;
}
.result-card {
	z-index: unset !important;
}
.md-autocomplete.md-field {
	margin: 0;
	min-height: 48px;
	padding-top: 0;
}
.md-autocomplete.md-field .md-input {
	min-height: 48px;
	line-height: normal;
	height: 48px;
	margin: 0;
}
.md-field {
	margin: 8px 0;
	min-height: 48px;
	display: flex;
	align-items: center;
}
.md-autocomplete.md-field.md-focused {
	border-color: #1dc7ea;
}
.md-autocomplete.md-field.md-invalid {
	border-color: #f44336;
}
.md-select-value {
	text-align: left !important;
	justify-content: flex-start !important;
}
.md-menu-content .md-list-item-content {
	justify-content: flex-start !important;
	padding-left: 8px;
}
.md-autocomplete .md-menu {
	width: 100%;
	max-width: 100%;
}

.md-autocomplete-box {
	width: 100%;
	text-align: left !important;
}

.md-menu-content {
	width: 100%;
	max-width: 100%;
}

.md-list-item-content {
	justify-content: flex-start !important;
	padding-left: 8px;
}
</style>
